import React, { useEffect, useState } from 'react';
import { getConfig } from 'src/components/AppConfig';
import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { trackError } from '@local/metrics';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { App } from 'src/components';

let ldClient: LDClient | null = null;

export function LaunchDarklyProviderWrapper() {
    const [loaded, setLoaded] = useState(false);
    const config = getConfig();

    // Only initialise the client if the global client is null - ie it hasn't been loaded before.
    if (ldClient === null) {
        ldClient = initialize(config.launchDarklyClientID, { anonymous: true });
    }

    useEffect(() => {
        if (ldClient !== null) {
            ldClient
                .waitForInitialization()
                .catch((reason) => {
                    trackError(reason, 'Error initialising LD client, rendering defaults');
                    // eslint-disable-next-line no-console
                    console.warn('Error initialising LD client, rendering defaults');
                })
                .finally(() => setLoaded(true));
        }
    }, []);

    if (loaded && Object.keys(config).length) {
        const LDProvider = withLDProvider({ clientSideID: config.launchDarklyClientID, ldClient })(
            App as React.ComponentType,
        );
        return <LDProvider />;
    }

    return null;
}